

@media screen and (min-width: 600px) {

    
  .swiper-container {
      width: 100%;
      max-width: 100%;
      max-height: 100vh;
  
      min-height: 0;
      min-width: 0;
  }
  
  .swiper-pagination {
    top: 2px;
  }

  .panel-swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .panel-swiper-slide img {
  
    margin-top: 8%;
    display: block;
    max-height: 70vh;
    max-width: 80vw;
  }

  @media screen and (max-height: 450px) {

    .panel-swiper-slide img {
      margin-top: 3%;
      display: block;
      max-height: 80vh;
      max-width: 90vw; 
    }
    
  }

}

/* @media screen and (max-height: 450px) {
  .panel-swiper-slide img {
    display: block;
    max-height: 90vh;
    max-width: 90vw; 
  }
} */



.swiper-pagination {
top: 2px;
}

@media screen and (max-width: 599px) {


  
  .panel-swiper-container {
      width: 100%;
      max-width: 100vw;
      max-height: 100vh;
  
      min-height: 0;
      min-width: 0;
  }

  
  .panel-swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .panel-swiper-slide img {
    display: block;
    max-height: 90vh;
    max-width: 90vw; 
  }
  
  
}    
