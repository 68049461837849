

@media screen and (min-width: 600px) {
  .App {
    /* background: rgb(51, 50, 50); */
    background-image: url("./assets/GOL_Background.jpg");
    background-size: 100vh;
    height: 100%;
    margin-top: 0;
  }
  @media screen and (max-height: 450px) {
    .App {
      height: fit-content;
    }
  }
}


@media screen and (max-width: 599px) {
  
  .App {
    /* background: rgb(51, 50, 50); */

    background-image: url("./assets/GOL_Background.jpg");
    background-size: 100vh;

    height: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}


.page-container {
  height: fit-content;

  display: flex;
  flex-direction: row;
}

.image-container {

  margin: 20px;
  /* width: 650px;*/
  height: fit-content;  


  /* overflow-y: scroll; */
  box-shadow: 0 25px 50px 0 rgba (62, 62, 62, 0.15);
  -webkit-box-shadow: 0 25px 50px 0 rgb(62 62 62 / 15%);
}

.next-button {
  float: right;
}

/* 
.image {
  height: 500px;
} */

/* .footer {
  display: flex;
  justify-content: space-between;
  width: 500px;
} */
.button-container {
  display: flex;
  gap: 8px;
}




/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
