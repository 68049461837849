body {
  margin: 0;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/GOL_Background.jpg");
  background-size: cover;
  background-color: black;
  /* width: 100%; */
 /* background: rgb(51, 50, 50); */
  
}

@media screen and (max-height: 500px) {
  .logo { 
    display: none;
  }
}
.logo {
  width: 9vw;
  position: absolute;
  bottom: 3vh;
  left: 1vw;
  z-index: 1;
}

html {
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
