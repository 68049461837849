
.swiper-button-next,
.swiper-button-prev {
  color: rgb(190, 190, 190) !important;
  size: 20px;
}

.swiper-pagination {
  top: 2px;
}


@media screen and (min-width: 600px) {

    .swiper-pagination {
      top: 2px;
    }
    
    .swiper-container {
        width: 100%;
        max-width: 100%;
        max-height: 100vh;
        min-height: 0;
        min-width: 0;

    }
    
    .swiper-slide {
      text-align: center;
      font-size: 18px;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    
    .swiper-slide img {
      display: block;
      max-height: 100vh;
      max-width: 100vw;
    }

    @media screen and (max-height: 450px) {
      .swiper-slide img {
        display: block;
        max-height: 90vh;
        max-width: 100vw;
      }
    }
}


@media screen and (max-width: 599px) {

  .container {
    height: 100%;
    margin: 0;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .logo {
    display: none;
  }

  .swiper-pagination {
    top: 2px;
  }

  .swiper {
    height: 100%
  }
  
  .swiper-container {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      min-height: 0;
      min-width: 0;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    max-height: 100vh;
    max-width: 100vw;
  }

}  


